import $ from "jquery";
import "slick-carousel";

window.jQuery = window.$ = $;
export const initSliders = () => {
    const slider = $(".slider");
    slider.slick({
        dots: true,
        arrows: true,
        infinite: true,
        focusOnChange: true,
        accessibility: true,
    });

    $(document).ready(function() {
        const buttons = $('.slider').find('button');
        buttons.attr('tabindex', '0');
    });
};
export default initSliders;
