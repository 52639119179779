import $ from "jquery";

export const initMenuFunctions = () => {
  // set the mobile menu hamburger icon
  $(document).on("click", ".hamburger", function () {
    $(this).toggleClass("is-active");

    if ($(".mobile-menu").hasClass("hidden")) {
      $(".mobile-menu").removeClass("hidden slideOutRight");
      $(".mobile-menu").addClass("slideInRight");
    } else {
      $(".mobile-menu").removeClass("slideInRight");
      $(".mobile-menu").addClass("slideOutRight");

      setTimeout(() => {
        $(".mobile-menu").addClass("hidden");
      }, 300);
    }
  });


  // accessibility - open children menu on enter key
  $(document).keyup(function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      $(e.target).parent(".has-children").addClass("open");
    }
  });

  // accessibility - close children menu on esc key
  $(document).keyup(function (e) {
    if (e.keyCode === 27 && $(".has-children").hasClass("open")) {
      e.preventDefault();
      $(".has-children").removeClass("open");
    }
  });
};

export default initMenuFunctions;