export const initUtilities = () => {

    // FAQ Questions
    $(document).on('click', '.faq-question', function () {
        $(this).toggleClass('open');
        $(this).next().slideToggle(200);
    });

    $(document).on('keydown', '.faq-question', function(e) {
        if (e.key === 'Enter') {
            $(this).trigger('click');
        }
    });

    // MAP
    $(document).ready(function() {
        // Handle click event on region links
        $('.mobile-region a').on('click', function(event) {
            event.preventDefault();
            $('.mobile-region-selector').removeClass('active');
            $(this).addClass('active');
            const regionTitle = $(this).data('title');
            const regionInfoDiv = $('#information-' + regionTitle + '-mobile');
            $('.region-information-mobile').addClass('hidden');
            regionInfoDiv.removeClass('hidden');
        });
    });

    $(document).on('click', '.region-pin', function () {
        let region = $(this).data('title');
        let regionElement = $('#' + region);
        $('.cls-2').removeClass('active');
        regionElement.addClass('active');
        $(this).addClass('active');

        // Show the overlay
        let regionInfoElement = $('#information-' + region);
        regionInfoElement.show();

        // Hide all other cards
        $('.card-information').hide();
        $('#card-' + region).show();

        let offSetElement = $('.offset-' + region);

        $('html, body').animate({
            scrollTop: offSetElement.offset().top - ($(window).height() / 2) + (offSetElement.height() / 2)
        });

        // add animation to the regioninfoelement on show
        $('.region-information-container').removeClass('slideOutRight').addClass('slideInRight');
    });

    $(document).on('click', '.region-information', function (e) {
        if ($(e.target).closest('.region-information-container').length === 0) {
            $('.cls-2').removeClass('active');
            $('.region-pin').removeClass('active');
            $('.region-information-container').removeClass('slideInRight').addClass('slideOutRight');

            setTimeout(() => {
                $('.region-information').hide();
            }, 300);
        }
    });

    $(document).on('click', '.close-btn', function () {
        $('.cls-2').removeClass('active');
        $('.region-pin').removeClass('active');
        $('.region-information-container').removeClass('slideInRight').addClass('slideOutRight');

        setTimeout(() => {
            $('.region-information').hide();
        }, 300);
    });

    $(document).on('keydown', function (e) {
        if (e.key === 'Escape') {
            $('.cls-2').removeClass('active');
            $('.region-pin').removeClass('active');
            $('.region-information-container').removeClass('slideInRight').addClass('slideOutRight');
            let region = $(this).data('title');
            let regionElement = $('#' + region);
            regionElement.removeClass('active');
            $('.region-pin').removeClass('active');


            setTimeout(() => {
                $('.region-information').hide();
            }, 300);
        }
    });


    // WINNERIES
    $(document).on('click', '.trigger-list', function () {
        $(this).addClass('active');
        $('.trigger-map').removeClass('active');
        $('#map').hide();
        $('#list').show();
        $('#list').css('display', 'flex');
    })

    $(document).on('click', '.trigger-map', function () {
        $(this).addClass('active');
        $('.trigger-list').removeClass('active');
        $('#list').hide();
        $('#list').css('display', 'none');
        $('#map').show();
    })


    // TEAM BLOCK
    $('.team-member-link').on('click', function(event) {
        const member =  $(this).data('link')
        const memberElement = $('#' + member);

        if ($(memberElement).hasClass('hidden')) {
            memberElement.removeClass('hidden');
            memberElement.addClass('flex');
        } else {
            memberElement.addClass('hidden');
            memberElement.removeClass('flex');
        }

    });

    $(document).on('keydown', '.team-member-link', function(e) {
        if (e.key === 'Enter') {
            $(this).trigger('click');
        }
    });

    // close team member
    $(document).on('click', '.close-btn', function () {
        $('.team-member-overlay').addClass('hidden');
    });

    $(document).on('keydown', function (e) {
        if (e.key === 'Escape') {
            $('.team-member-overlay').addClass('hidden');
        }
    });

    // close team member on click outside
    $(document).on('click', function (e) {
        if ($(e.target).closest('.team-member-container').length === 0 && $(e.target).closest('.team-member-link').length === 0) {
            $('.team-member-overlay').addClass('hidden');
        }
    });

    // ARTICLE FILTERS
    $(document).on('click', '.filter-button', function () {
        $('.filter-button').removeClass('active');
        $(this).addClass('active');
        $('.filtered-article').hide();
        const filter = $(this).data('filter');
        $(`.${filter}-card`).show();
    });
}

export default initUtilities;